import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SunIcon} from "@heroicons/react/16/solid";
import './List.css'
const ListItem = ({space, baseUrl}) => {

    const {name, media, slug, attributes, location, earliest_open} = space || {}
    const {description} = attributes || ""
    const subLocation = location?.split(',')

    return (
        <div className={'spaces-list__item group '}>
            <Link to={`/spaces/${slug}`} title={name} className={'group-hover:elevation-1'}>
                {(media ?? false) && (
                    <img title={name} src={`${baseUrl}${media[0]}`}
                         className={'w-full min-h-full object-cover object-center'}
                         loading={"lazy"}
                         alt={name}/>
                )}
                <div className={'spaces-list__item_content'}>
                    <div className={'title-container'}>
                        <div className={'title'}>{name}</div>
                        <p className={'flex flex-row items-center'}>
                            <FontAwesomeIcon icon={'fa-solid fa-location-dot'} className={'size-4 text-blue-950'}/>
                            <span
                                className={'text-blue-950 font-lora font-medium text-sm ml-2 text-oblique'}>{ subLocation && (subLocation[1] ?? "")}</span>
                        </p>
                    </div>

                    <div className={'content line-clamp-4'} dangerouslySetInnerHTML={{__html: description}}/>

                    <p className={'text-xs mt-4 flex flex-row items-center'} ><SunIcon className={'size-4 mr-2 text-yellow-700'} /> Early arrival from {earliest_open?.open_time} on {earliest_open?.day_of_week}</p>
                </div>
            </Link>
        </div>
    )


}

export default ListItem