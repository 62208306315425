import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CohesiiContentComponent} from "../../../lib";

const TimelineStepComponent = ({content:data}) => {

    const {title, tag, content, image, image_alt, image_title, link, link_title, link_url, _uid} = data



    const headingData = {
        data: {
            'text': title,
            'tag': tag || 'h3',
            'classname': 'strapline'
        },
        type: 'title'
    }


    const imageData = {
        data: {
            'asset': image,
            'asset_alt': image_alt || title,
            'asset_title': image_title || title,
            'extra_classes': "mt-4 mb-4"
        },
        type: 'asset'
    }


    const contentData = {
        data: {
            'content': content || '',
            'extra_classes': 'content'
        },
        type: 'code'
    }


    return (
        <div id={_uid} className={'column !gap-0'}>
            <CohesiiContentComponent content={headingData}/>
            <CohesiiContentComponent content={contentData}/>
            {link && (<Link to={link_url} title={link_title} className={'btn btn-link mb-12 mt-[-25px]'}>
                <span className={'mr-2'}> {link}</span>
                <FontAwesomeIcon icon={'fa-solid fa-arrow-right'} />
            </Link>)}
            <CohesiiContentComponent content={imageData}/>
        </div>
    )
}

export default TimelineStepComponent