import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice';
import csrfReducer from './features/csrf/csrfSlice';
import dataReducer from './features/data/dataSlice';
import { marketingApi } from './services/marketingApi';
import {spacesApi} from "./services/spacesApi";
import {blogApi} from "./services/blogApi";

const store = configureStore({
    reducer: {
        csrf: csrfReducer,
        auth: authReducer,
        data: dataReducer,
        [marketingApi.reducerPath]: marketingApi.reducer,
        [spacesApi.reducerPath]: spacesApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .concat(spacesApi.middleware)
            .concat(marketingApi.middleware)
            .concat(blogApi.middleware),
});

export default store