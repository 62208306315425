import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET; // Use a secure key, ideally stored in an environment variable


// Encrypts the data
export const encryptData = (data) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
};

// Decrypts the data
export const decryptData = (ciphertext) => {
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (e) {
        return null; // In case decryption fails
    }
};