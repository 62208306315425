import React, {useEffect} from 'react';
import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import NotFound from "./features/Components/Error/NotFound";
import Error from "./features/Components/Error/Error";
import {useLoading} from "./lib/LoadingContext";

const  ErrorBoundary = () =>  {


    const error = useRouteError()

    const { setLoading } = useLoading(); // Use loading context to show/hide the loading bar

    useEffect(() => {
        return () => {
            setLoading(false);  // Ensure loading is stopped when the component unmounts
        };
    }, [setLoading]);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <>
                    <Helmet>
                        <title>Cohesii | 404 Page not found </title>
                        <meta name='description' content={"404 Page not found"} />
                    </Helmet>

                    <NotFound />
                </>
            );
        }

    }

    return (
        <>
            <Helmet>
                <title>Cohesii | {error.status} We ran into an error </title>
                <meta name='description' content={`${error.status} We ran into an error`} />
            </Helmet>
            <Error />
        </>
    );
}

export default ErrorBoundary;