import {useEffect, useState} from "react";
import {useGetPostsMutation} from "../../../app/services/blogApi";
import {Link, useSearchParams} from "react-router-dom";
import ListItem from "./ListItem";
import Loading from "../Loading";

const BlogPostList = ({setPagination = null, setCats = null, id=null}) => {


    const [searchParams, setSearchParams] = useSearchParams();

    // Get the current page from the query parameter, default to 1 if not present
    const pageNumber = parseInt(searchParams.get("p")) || 1;

    const [posts, setPosts] = useState([]);


    const [getPosts, {isLoading}]= useGetPostsMutation()

    const getPostRequest = async () => {
        if (!isLoading) {
            const response = await getPosts({
                catId: id,
                search: '',
                page: pageNumber
            })

            setPosts(response?.data?.data); // Assuming your API returns posts in 'data'

            if (setPagination) {
                setPagination(response?.data?.pagination);
            }

            if (setCats) {
                setCats(response?.data?.categories);
            }
        }
    }

    useEffect(() => {

        getPostRequest()
    }, [id, pageNumber]);


    return (
        <div className={'grid grid-cols-1 md:grid-cols-2 gap-8'}>
            <Loading isLoading={isLoading} />
            {!isLoading &&
                posts?.map((post, index) => (
                    <ListItem item={post} key={'post_'+post.id} />

                ))
            }

            {posts?.length < 1 &&  (
                <div className={'comming-soon col-span-4 flex flex-col gap-4'}>
                    <h3 className={'text-2xl font-bold text-blaze-500 font-montserrat'}>Stay Tuned! Exciting Content Coming Soon</h3>
                    <p>We’re always working on fresh and insightful content to keep you informed and inspired. Please be patient as we prepare something special for this category. Be sure to check back soon for updates—there’s a lot in store!</p>

                    <p>In the meantime, feel free to explore other sections of our blog, and remember, new articles are just around the corner!</p>

                    <Link to={'/blog'} className={'btn-primary'}>
                        Back to blog
                    </Link>
                </div>
            )}



        </div>
    )

}

export default BlogPostList