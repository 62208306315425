const Loading = ({isLoading}) => {

    return (
        <>
            {isLoading &&
                <div className="flex mt-12 items-center justify-center">
                    <div
                        className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-blaze-500 border-t-transparent"></div>
                </div>
            }
        </>
    )

}

export default Loading