const Checkbox = ({...rest}) => {

    const {
        label = false,
        checked = false,
        error = false,
        name,
        errorMessage = `${label} is required field`,
        required = false,
        showLabel = false,
        value = null,
        handleInputChange,
        validateFieldChange
    } = rest || {}


    return (

        <div className={`checkbox-field ${rest.className || ''}` }>

            <div className={'flex gap-2'}>
                <input
                    onChange={(e) => {
                            let value = e.target.checked ?  e.target.value : null
                            e.target.value = value
                            handleInputChange(e)
                            validateFieldChange(name, value);
                        }
                    }
                    name={name} type="checkbox" required={required} id={name} checked={checked} value={value} />
                {label && (
                    <label htmlFor={name} className={`${!showLabel ? 'sr-only': ''}`}>
                        {label} {required && (<span className={'text-red-500'}>*</span>)}
                    </label>
                )}
            </div>

            {error && <span className="error text-red-500 mt-1">{errorMessage}</span>}
        </div>
    )
}

export default Checkbox