import LatestListWidget from "../Components/Blog/LatestListWidget";

const LatestBlogs = ({content}) => {


    const {classes, _uid} = content;

    return (
        <section id={_uid} className={'latest-blogs  order-10'}>

            <div className={'container row'}>
                <div className={'column justify-center'}>
                    <div className={'title-container mb-6'}>
                        <p className={'strapline'}>Latest Blogs</p>
                        <h4 className={'title'}>Keep up to date with the latest news from Cohesii</h4>
                    </div>

                    <LatestListWidget limit={4} />
                </div>
            </div>



        </section>
)
}

export default LatestBlogs