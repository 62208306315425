import { createSlice } from '@reduxjs/toolkit';
import {getToken, removeToken} from "../../helpers/local-storage";

const initialState = {
    user: null,
    token: getToken(),
    isAuthenticated: !!getToken(),
    ipAddress: null,
    location: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIpAddress: (state, action) => {
            state.ipAddress = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload?.user;
            state.token = action.payload?.token;
        },
        logout: (state) => {
            state.token = null;
            state.user = null;
            removeToken();
        },
    },
});

export const {
    logout,
    setUser,
    setIpAddress,
    setLocation
} = authSlice.actions;

export default authSlice.reducer;