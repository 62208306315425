import {Link} from "react-router-dom";

const Cta = ({content}) => {
    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {
        background,
        background_alt,
        background_title,
        show_overlay = 'no',
        title = '',
        cta: link,
        cta_url: link_url,
        cta_title: link_title,
        _uid,
        classname

    } = content

    return (
        <section id={_uid} className={`banner-cta group ${classname || ''}`}>
            <img loading={"lazy"} src={`${baseUrl}${background}`} alt={background_alt || ''} title={background_title ||''}/>

            <div className={`overlay  ${show_overlay === 'yes' ? 'gradient-overlay' : ''}`}>

                <Link to={link_url} title={link_title}>
                    <div className={'title-container'}>
                        {title.split(' ').map((value, index) => (
                            <p key={_uid+'_word_'+index}
                            >{value.toUpperCase()}</p>
                        ))}
                    </div>
                    {link && (<div className={'btn-primary group-hover:bg-blaze-600'}>
                        <span>{link}</span>
                    </div>)}
                </Link>

            </div>

        </section>
    )
}

export default Cta