import React, { forwardRef } from "react";
import {getComponent} from "./index";

const ContentComponent = forwardRef(
    ({ content, ...restProps }, ref) => {

    if (!content) {
        console.error(
            "Please provide a 'content' property to the ContentComponent"
        );
        return (
            <div>Please provide a content property to the ContentComponent</div>
        );
    }

    const Component = getComponent(content.type);


    if (Component) {
        return <Component content={content.data} {...restProps} />;
    }

    return <div></div>;
})

ContentComponent.displayName = "CohesiiContentComponent"

export default ContentComponent;