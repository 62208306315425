import {CohesiiContentComponent} from "../../lib";


const TwoColumnTextImage =({content, order=1}) => {

    const  {
        classname,
        strapline,
        additional = [],
        title,
        tag:Tag,
        asset,
        asset_title,
        asset_alt,
        "image-position":image_position,
        content:html ,
        image_size_percent="40",
        style,
        _uid=""} = content

    const imageData = {
        data: {asset: asset, asset_title: asset_title, asset_alt: asset_alt },
        type: 'asset'
    }

    const renderedClass = `${classname || ''} image-${image_position || 'start'} style-${style || ''}`.trim()
    return (
        <section id={_uid} className={`order-${order} two-column-image-text ${renderedClass}`.trim()}>
            <div className={'container row'}>
                <div className={'column justify-center'}>
                    <div className={'title-container'}>
                        <p className={'strapline'}>{strapline}</p>
                        <Tag className={'title'}>{title}</Tag>
                    </div>
                    <div className={'content'} dangerouslySetInnerHTML={{__html: html}}/>

                    {additional.length > 0 && (
                        <div className={'additional column'}>
                            {
                                additional.map((nested, index) => (
                                    <CohesiiContentComponent key={"two-column_"+nested.data._uid+index || "two-column_"+index} content={nested} />
                                ))
                            }
                        </div>
                    )}
                </div>
                {/** lg:max-w-[50%] lg:max-w-[30%] lg:max-w-[40%]**/}
                <div className={`column max-w-full lg:max-w-[${image_size_percent}%]`}>
                    <CohesiiContentComponent content={imageData} />
                </div>
            </div>
        </section>
    )


}

export default TwoColumnTextImage