import {CohesiiContentComponent} from "../../lib";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Arrow from '../../assets/arrow.svg'
import {encryptData} from "../../app/helpers/crypto";
import {Link, redirect} from "react-router-dom";
import React from "react";

const schema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    surname: yup.string().required("Last name is required"),
    email: yup.string().email("Email must be valid").required("Email is required"),
});

export const BannerRegister = ({content, order=1}) => {

    const {strapline, title, classname = "", _uid,  additional = [], type} = content || {}

    const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const titleData = {
        data: {
            text: title
        },
        type: 'title'
    }

    const onSubmitHandler = (data) => {
        const payload = {
            'role': type,
            'firstName': data.firstname,
            'lastName': data.surname,
            'email': data.email,
        }
        const token = encryptData(payload)
        reset();

        window.location.href = process.env.REACT_APP_BASE_PORTAL+'auth/register?token='+token;

    };

    return (
        <section id={`${_uid || ""} `} className={`banner row order-${order} ${classname || ""}`.trim()}>
            <div className={'container mx-auto row relative'}>
                <div className={'column py-[20px] md:pb-[180px] relative'}>
                    <div className={'title-container'}>
                        {strapline && (<p className={'strapline'}>
                            {strapline}
                        </p>)}
                        <CohesiiContentComponent content={titleData}/>
                    </div>

                    <div className={'content'} dangerouslySetInnerHTML={{__html: content?.content}}/>

                    <div className={'additional'}>
                        {additional.map((nested, index) => (
                            <CohesiiContentComponent key={nested?.data?._uid || index} content={nested}/>
                        ))}
                    </div>

                    <div
                        className={'absolute hidden md:block bottom-[-20px] md:right-[-100px] lg:right-[120px] xl:right-[-90px]'}>
                        <img src={Arrow} alt={'Orange hand-drawn arrow pointing to register form'} title={'arrow'}/>
                    </div>
                </div>
                <div className={'column '}>
                    <div className={'register'} id={'register'}>
                            <form onSubmit={handleSubmit(onSubmitHandler)}>
                                <h3 className={'title'}>Signup today</h3>
                                <p>Ready to get started? Complete the registration form and begin your journey towards earning more with your space!</p>

                                <div className={'flex flex-row gap-[30px]'}>
                                    <div className={'input-field'}>
                                        <label htmlFor={"firstname"}>
                                            Firstname <span className={'text-red-500'}>*</span>
                                        </label>
                                        <input
                                            {...register("firstname")}
                                            id={"firstname"}
                                            type={"text"}
                                            required={true}
                                            placeholder={"Enter firstname"}
                                            aria-label={"Firstname"}
                                            className={errors?.firstname ? '!border-red-500' : ''}
                                        />

                                        {errors?.firstname && <span
                                            className="error text-red-500 mt-1">{errors?.firstname.message}</span>}
                                    </div>

                                    <div className={'input-field'}>
                                        <label htmlFor={"surname"}>
                                            Lastname <span className={'text-red-500'}>*</span>
                                        </label>
                                        <input
                                            {...register("surname")}
                                            id={"surname"}
                                            type={"text"}
                                            required={true}
                                            placeholder={"Enter lastname"}
                                            aria-label={"Lastname"}
                                            className={errors?.surname ? '!border-red-500' : ''}
                                        />

                                        {errors?.surname && <span
                                            className="error text-red-500 mt-1">{errors?.surname.message}</span>}
                                    </div>
                                </div>

                                <div className={'input-field'}>
                                    <label htmlFor={"email"}>
                                        Email <span className={'text-red-500'}>*</span>
                                    </label>
                                    <input
                                        {...register("email")}
                                        id={"email"}
                                        type={"email"}
                                        required={true}
                                        placeholder={"test@spaces.com"}
                                        aria-label={"Email"}
                                        className={errors?.email ? '!border-red-500' : ''}
                                    />

                                    {errors?.email && <span
                                        className="error text-red-500 mt-1">{errors?.email.message}</span>}
                                </div>

                                <button
                                    type={"submit"}
                                    disabled={!isValid}
                                    className={'btn-primary w-full mt-4 flex flex-row justify-center cursor-pointer disabled:hover:text-white'}>
                                    <span className={'text-xl mr-2'}>Get Started</span>
                                </button>
                                <div>
                                <p className={'text-center mt-6 text-gray-500 text-sm'}>By signing up, you agree to our Terms of Service and Privacy Policy.</p>
                                <p className={'text-center mt-2 text-gray-500 text-sm'}>Already have an account?
                                    <Link to={`${process.env.REACT_APP_BASE_PORTAL}auth/login`} title={'Spaces Hub'} className={'ml-2 text-blaze-500 font-bold'}>Login Here</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
            </div>
        </section>
    )


}


