import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    activityCats: null,
    featuredActivity: null,
    latestSpaces: null,
    blogCats: null,
    latestBlogs: null,
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setActivityCats(state, action) {
            state.activityCats = action.payload;
        },
        setFeaturedActivity(state, action) {
            state.featuredActivity = action.payload;
        },
        setLatestSpaces(state, action) {
            state.latestSpaces = action.payload;
        },
        setBlogCats(state, action) {
            state.blogCats = action.payload;
        },
        setLatestBlogs(state, action) {
            state.latestBlogs = action.payload;
        },
    },
});

export const {
    setActivityCats,
    setFeaturedActivity,
    setLatestSpaces,
    setBlogCats,
    setLatestBlogs,
} = dataSlice.actions;


export default dataSlice.reducer;