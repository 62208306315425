// src/features/csrf/csrfSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to fetch the CSRF cookie
export const fetchCsrfToken = createAsyncThunk('csrf/fetchCsrfToken', async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`, {
        credentials: 'include',
    });
    return response.ok;
});

const csrfSlice = createSlice({
    name: 'csrf',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCsrfToken.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCsrfToken.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchCsrfToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default csrfSlice.reducer;