import './input.css'
const TextInput = ({...rest}) => {

    const {
        label = false,
        error = false,
        name,
        errorMessage = `${label} is required field`,
        placeholder = label,
        type = 'text',
        required = false,
        showLabel = false,
        value = null,
        handleInputChange,
        validateFieldChange = () => {}
    } = rest || {}


    return (
        <div className={`${type !=='hidden' ? 'input-field' : ''}`}>
            {label && (
                <label htmlFor={name} className={`${!showLabel ? 'sr-only': ''}`}>
                    {label} {required && (<span className={'text-red-500'}>*</span>)}
                </label>
            )}

            <input value={value}
                   name={name}
                   id={name}
                   type={type}
                   autoComplete={name}
                   required={required || false}
                   placeholder={placeholder || ''}
                   aria-label={label || name || ''}
                   className={error ? '!border-red-500' : ''}
                   onChange={handleInputChange}
                   onBlur={(e) => validateFieldChange(name, e.target.value)}
            />

            {error && <span className="error text-red-500 mt-1">{errorMessage}</span>}

        </div>
    )
}

export default TextInput