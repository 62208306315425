import {CohesiiContentComponent} from "../../lib";

const ContentCenter = ({content, order=1}) => {

    const {blocks, classname, style, title, content:html, _uid} = content


    return (
        <section id={`${_uid || ""}`} className={`content-center flex flex-col flex-1 order-${order} ${style || ''} ${classname || ""}`.trim()}>


            <div className={'wrapper'}>
                <h2 className={'title'}>{title}</h2>
                <div className={"content"} dangerouslySetInnerHTML={{ __html: html }} />
                {
                    blocks.map((nested, index) => (
                        <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                    ))
                }
            </div>
        </section>
    )
}

export default ContentCenter