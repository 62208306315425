import Image from './image.svg'
import {Link} from "react-router-dom";

const ErrorPage = () => {

    return (
        <div className="container max-auto flex">
            <div className={'flex flex-col flex-1 min-w-full md:flex-row justify-stretch items-center  text-gray-700'}>
            <div className="py-[100px]  flex-1">
                <h1 className="text-8xl font-montserrat font-bold mb-6 text-blue-950">500</h1>
                <h3 className="text-3xl md:text-4xl font-light text-blaze-500 leading-normal mb-3">Sorry we have run into a problem</h3>

                <p className="mb-8 text-gray-500 text-2xl">But dont worry, we should be back working shortly.</p>

                <Link to={'/'} title={'Back to home'} className={'btn-primary'}>
                    Back to home
                </Link>
            </div>
            <div className=" flex-1 justify-center text-center flex ">
                <img src={Image} alt={"cat knocking over a vase"} title={"404 page not found"}/>
            </div>
            </div>
        </div>
    )

}

export default ErrorPage