import React, {lazy, Suspense, useEffect, useState} from 'react';
import {defaultCountries, parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css'; // Keep the CSS import

// Lazy load the PhoneInput component and google-libphonenumber
const PhoneInput = lazy(() => import('react-international-phone').then(module => ({ default: module.PhoneInput })));
let phoneUtilInstance = null;

// Lazy load PhoneNumberUtil instance
const loadPhoneNumberUtil = async () => {
    if (!phoneUtilInstance) {
        const libphonenumber = await import('google-libphonenumber');
        phoneUtilInstance = libphonenumber.PhoneNumberUtil.getInstance();
    }
    return phoneUtilInstance;
};

// Asynchronous phone validation function
export const isPhoneValid = async (phone) => {
    try {
        const phoneUtil = await loadPhoneNumberUtil();
        const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
        return phoneUtil.isValidNumber(parsedPhone);
    } catch (error) {
        return false;
    }
};

// Filter countries as before
const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['us', 'gb'].includes(iso2);
});

const PhoneInputWrapper = ({ ...rest }) => {
    const {
        label = "Phone Number",
        required = false,
        name = "phone",
        ariaLabel = "phone",
        value,
        handleInputChange,
        validateFieldChange,
        error = false,
        errorMessage = `${label} is required and must be valid`,
    } = rest;

    // Track phoneUtil as state for lazy loading
    const [phoneUtil, setPhoneUtil] = useState(null);

    useEffect(() => {
        // Load PhoneNumberUtil lazily when the component is mounted
        loadPhoneNumberUtil().then((util) => setPhoneUtil(util));
    }, []);

    return (
        <div className={'input-field group'}>
            <label htmlFor={name}>
                {label} {required && <span className={'text-red-500'}>*</span>}
            </label>

            {/* Suspense to lazily load the PhoneInput component */}
            <Suspense fallback={<div>Loading phone input...</div>}>
                <PhoneInput
                    name={name}
                    defaultCountry="gb"
                    required={true}
                    country="gb"
                    id={name}
                    aria-label={ariaLabel}
                    value={value}
                    countries={countries}
                    onBlur={(e) => validateFieldChange("phone_number", e.target.value)}
                    onChange={(phone) => handleInputChange({ target: { name: name, value: phone } })}
                />
            </Suspense>

            {error && <span className="error text-red-500 mt-1">{errorMessage}</span>}
        </div>
    );
};

export default PhoneInputWrapper;
