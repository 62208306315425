import Loading from "../Loading";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {useLoading} from "../../../lib/LoadingContext";
import Breadcrumbs from "../Breadcrumbs";
import {useLoadActivityCats} from "../../../hooks/useBaseData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet-async";

const Activities = () => {

    const {activityCats, isLoading}= useLoadActivityCats()

    const { setLoading } = useLoading(); // Use loading context to show/hide the loading bar

    useEffect(() => {
        setLoading(true);  // Trigger loading state when the component mounts

        // Stop loading when data has been successfully loaded
        if (!isLoading && activityCats) {
            setLoading(false);
        }

        return () => {
            setLoading(false);  // Ensure loading is stopped when the component unmounts
        };
    }, [activityCats, setLoading]);


    const schemaData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://cohesii.com/home"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "All Activities",
                "item": window.location.href
            }
        ]
    }

    const title = "All Space Activities"
    const meta_description = "Whether you're planning a business meeting, a memorable event, or need a creative studio, we have a variety of categories to help you easily find the space that suits you best"
    return (
        <>
            <Helmet>
                <title>Cohesii | All Activities</title>

                <meta name="description"
                      content={meta_description ?? ""}/>
                <meta property="og:title" content={"Cohesii - " + (title)}/>
                <meta property="og:description" content={meta_description ?? ""}/>

                <meta property="og:image" content="https://cohesiiinfracdnendpoint-staging.azureedge.net/spaces/categories/01J8M3563NVXND3HM8M8GY755Q.jpg"/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={"Cohesii - " + (title)}/>
                <meta name="twitter:description" content={meta_description ?? ""}/>
                <meta name="twitter:image" content="https://cohesiiinfracdnendpoint-staging.azureedge.net/spaces/categories/01J8M3563NVXND3HM8M8GY755Q.jpg"/>

                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>


            </Helmet>


            <div className={'container py-8 pb-0'}>
                <Breadcrumbs crumbs={[
                    {path: '/', label: 'Home'},
                    {path: '/spaces', label: 'Spaces'},
                    {path: '/spaces/activities', label: 'All Activities'},
                ]}/>
            </div>

            <div className={'container'}>

                <h1 className={'mt-[50px] title !text-3xl '}>All Activities</h1>
                <p className={'text-lg text-gray-500 mt-2'}>Welcome to our "All Activities" category list! Whether
                    you're planning a business meeting, a memorable event, or need a creative studio, we have a variety
                    of categories to help you easily find the space that suits you best. Explore our listings and
                    discover the perfect spot tailored to your unique needs.</p>
        <Loading isLoading={isLoading && !activityCats}/>

        {(!isLoading && activityCats) &&
            <div className={
                'activity_list py-[50px] pt-0'
            }>
            {activityCats.map((item, index) => (
                        <div className={'acitivity_parent'} key={'parent_' + item?.id}>
                            <h3 className={'mt-12 text-2xl text-gray-800 pb-4 border-b-[1px] border-blue-950 group'}>
                                <Link to={`/spaces/activities/${item.slug}`} className={'flex flex-row hover:text-blaze-500'}><span className={'flex-1'}> {item?.name}</span>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" className={"transition transform group-hover:animate-bounceX ml-2"} /></Link>
                            </h3>
                            <div className={'acitivity-child grid gap-6 grid-cols-5 mt-4'} key={'child' + item?.id}>
                                {item?.subcategories.map((child, index) => (
                                    <div className={'group'} key={'child_' + child?.id}>
                                        <Link to={`/spaces/activities/${child.slug}`} className={'group transition-all flex flex-col gap-2  p-4 hover:bg-gray-50 pointer-cursor rounded-md'}>
                                            <h4 className={'text-gray-800 text-lg group-hover:text-blaze-500'}> {child?.name}</h4>
                                            <p className={'text-sm text-gray-500'} dangerouslySetInnerHTML={{__html: child?.description}}></p>
                                        </Link>
                                    </div>

                                        ))
                                    }
                                </div>
                            </div>
                            ))
                            }
                        </div>
                    }
                </div>
        </>
    )
}

export default Activities;