import {CohesiiContentComponent} from "../../lib";

const Column = ({content}) => {

    const {blocks, classname, _uid} = content


    return (
        <div id={`${_uid || ""}`} className={`flex flex-col flex-1 ${classname || ""}`.trim()}>
            {
                blocks.map((nested, index) => (
                    <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                ))
            }
        </div>
    )
}

export default Column