// src/services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL+'/marketing/',
    credentials: 'include', // Important to include cookies
});

export const marketingApi = createApi({
    reducerPath: 'marketingApi',
    baseQuery,
    endpoints: (builder) => ({
        subscribe: builder.mutation({
            query: (body) => ({
                url: `subscribe`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response) => response.data
        }),
        contact: builder.mutation({
            query: (body) => ({
                url: `contact`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response) => response.data
        }),
    }),
});

export const { useSubscribeMutation, useContactMutation } = marketingApi;