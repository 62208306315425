import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";

export const MultiSelect = ({  options, name, label, handleInputChange, defaultval= '', required=true, showLabel=false  }) => {

    const dropdownRef = useRef(null);

    const [selectedOptions, setSelectedOptions] = useState([defaultval]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    //set the parent element element on load
    useEffect(() => {
        if (defaultval !== '') {
            setSelectedOptions([defaultval])
            handleInputChange({target: {name: name, value: [defaultval]}})
        }
    },[defaultval]);


    // handle the select
    const handleSelect = (option) => {
        if (!selectedOptions.includes(option)) {
            let options =  [...selectedOptions, option]
            setSelectedOptions(options);
            handleInputChange({target: { name:name, value: options}})
        }
        setDropdownOpen(false);

    };

    const handleRemove = (option) => {
        let options = selectedOptions.filter((item) => item !== option)
        setSelectedOptions(options);
        handleInputChange({target: { name:name, value: options}})
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="input-field"  ref={dropdownRef}>
            {label && (
                <label htmlFor={name} className={`${!showLabel ? 'sr-only': ''}`}>
                    {label} {required && (<span className={'text-red-500'}>*</span>)}
                </label>
            )}

            <div className="relative select" key={name}>
                <div
                    className={`select-box ${dropdownOpen ? 'focused' : ''}`}
                    onClick={toggleDropdown}
                >
                    {selectedOptions.map((option, index) => (
                        <div
                            key={index}
                            className="selected"
                        >
                            <span>{option}</span>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="ml-2 cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemove(option);
                                }}
                            />
                        </div>
                    ))}
                    <div className="ml-auto">
                        <FontAwesomeIcon icon={faCaretDown}/>
                    </div>
                </div>
                {dropdownOpen && (
                    <div className="elevation-2 absolute w-full bg-white border rounded mt-1 max-h-60 overflow-y-auto z-10">
                        {options
                            .filter((option) => !selectedOptions.includes(option))
                            .map((option, index) => (
                                <div
                                    key={index}
                                    className="cursor-pointer p-2 hover:bg-gray-200"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultiSelect