import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import store from './app/store'
import { Provider } from 'react-redux'

import {
    RouterProvider,
} from "react-router-dom";


import { fetchCsrfToken } from './app/features/csrf/csrfSlice';

import CookieConsentComponent from "./lib/CookieConsent";
import {LoadingProvider} from "./lib/LoadingContext";


import {addFasIcons} from "./app/helpers/Icons";
import {initContentBuilder} from "./app/helpers/ContentInit";
import {HelmetProvider} from "react-helmet-async";
import {ModalProvider} from "./lib/Modal/ModalContext";
import {router} from "./app/helpers/Router";

addFasIcons()
initContentBuilder()


store.dispatch(fetchCsrfToken());



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

      <HelmetProvider>
          <Provider store={store}>
                  <CookieConsentComponent>
                      <ModalProvider>
                          <LoadingProvider>
                              <RouterProvider router={router} />
                          </LoadingProvider>
                      </ModalProvider>
                  </CookieConsentComponent>
          </Provider>
      </HelmetProvider>
  </React.StrictMode>
);
