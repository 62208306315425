import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ textColor='dark', crumbs }) => {
    return (
        <nav
            className={`breadcrumbs  ${textColor}`}
            aria-label="Breadcrumb"
            itemScope
            itemType="https://schema.org/BreadcrumbList"
        >
            <ol className="list">
                {crumbs.map((crumb, index) => {
                    const isLast = index === crumbs.length - 1;

                    return (
                        <>
                            {crumb.label &&
                                <li
                                    key={'crumb_'+crumb.label}
                                    className="inline-flex items-center"
                                    itemProp="itemListElement"
                                    itemScope
                                    itemType="https://schema.org/ListItem"
                                >
                                    {!isLast ? (
                                        <>
                                            <Link
                                                to={crumb.path}
                                                className={`item`}
                                                itemProp="item"
                                            >
                                                <span itemProp="name">{crumb.label}</span>
                                            </Link>
                                            <meta itemProp="position" content={index + 1} />
                                            <svg
                                                className="w-4 h-4 mx-2"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 5l7 7-7 7"
                                                ></path>
                                            </svg>
                                        </>
                                    ) : (
                                        <span
                                            className="last"
                                            itemProp="item"
                                        >
                                          <span itemProp="name">{crumb.label}</span>
                                          <meta itemProp="position" content={index + 1} />
                                        </span>
                                    )}
                                </li>
                            }
                        </>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;