// src/services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL+'/blog/',
    credentials: 'include', // Important to include cookies
});

export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery,
    endpoints: (builder) => ({
        getPosts: builder.mutation({
            query: ({
                        catId = null,
                        search = null,
                        page =1,

                    }) => ({
                url: `posts`,
                method: 'POST',
                body: {
                    "catId": catId,
                    "page":page,
                    "search": search,
                },
            })
        }),
        getLatest: builder.mutation({
            query: ({
                        catId = null,
                        limit =2,

                    }) => ({
                url: `latest`,
                method: 'POST',
                body: {
                    "catId": catId,
                    "limit": limit,
                },
            })
        }),
    }),
});

export const {
    useGetPostsMutation,
    useGetLatestMutation,
} = blogApi;