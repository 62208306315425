import {Link} from 'react-router-dom'
const ImageCta = ({content}) => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {
        background,
        background_alt,
        background_title,
        show_overlay = 'no',
        title = '',
        content: html = '',
        button: link,
        button_url: link_url,
        button_title: link_title,
        tag:Tag

    } = content

    return (
        <div className={`image-cta group`}>
            <img loading={"lazy"} src={`${baseUrl}${background}`} alt={background_alt || ''} title={background_title ||''}/>

            <div className={`overlay  ${show_overlay === 'yes' ? 'gradient-overlay' : ''}`}>

                <Link to={link_url} title={link_title}>
                        <div className={'title-container'}>
                            <Tag className={'cta-title'}>{title}</Tag>
                            <div className={'cta-content'} dangerouslySetInnerHTML={{__html: html}}/>
                        </div>
                        <div className={'btn-primary group-hover:bg-blaze-600'}>
                            <span>{link}</span>
                        </div>
                </Link>

            </div>

        </div>
    )

}

export default ImageCta