// LoadingContext.js
import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
    loading: true,
    setLoading: null,
});

export function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>
            {loading &&
                (
                    <div className='w-full min-h-screen'>
                        <div className='h-1.5 w-full bg-blaze-100 overflow-hidden'>
                            <div className='animate-progress w-full h-full bg-blaze-500 origin-left-right'></div>
                        </div>
                    </div>
                )}

            {children}
        </LoadingContext.Provider>
    );
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}