// src/services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL+'/spaces/',
    credentials: 'include', // Important to include cookies
});

export const spacesApi = createApi({
    reducerPath: 'spacesApi',
    baseQuery,
    tagTypes: ['Spaces', 'Space', 'SpaceCategories'],
    endpoints: (builder) => ({
        filterSpaces: builder.mutation({
            query: ({params = ''}) => ({
                url: `search${params}`,
                method: 'GET',
            }),
            transformResponse: (response) => response.original,
            providesTags: (result, error, body) => [{ type: 'Spaces', body }],
        }),
        getSpace: builder.query({
            query: (slug) => `space/${slug}`,
            transformResponse: (response) => response.data,
            providesTags: (result, error, slug) =>
                [{ type: 'Space', slug }]
        }),
        getCategories: builder.query({
            query: (slug) => `categories/all`,
            transformResponse: (response) => response.data,
            providesTags: (result, error, slug) =>
                [{ type: 'SpaceCategories', slug }]
        }),
        getCategoriesTree: builder.query({
            query: ({limit=null}) => `categories/tree${limit ? '?limit='+limit : ''}`,
            transformResponse: (response) => response.data
        }),
        getFeatured: builder.query({
            query: (slug) => `categories/featured`,
            transformResponse: (response) => response.data,
            providesTags: (result, error, slug) =>
                [{ type: 'SpaceFeaturedCategories', slug }]
        }),
    }),
});

export const { useGetCategoriesTreeQuery, useGetFeaturedQuery, useGetSpaceQuery, useGetCategoriesQuery,  useFilterSpacesMutation } = spacesApi;