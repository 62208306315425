import {createBrowserRouter, redirect} from "react-router-dom";
import ErrorBoundary from "../../ErrorBoundary";
import axios from "axios";
import Page from "../../features/Layouts/Page";
import PostType from "../../features/Components/Blog/PostType";
import Activities from "../../features/Components/ListSpaces/Activities";
import Category from "../../features/Components/ListSpaces/Category";
import {lazy, Suspense} from "react";
import Blog from "../../features/Components/Blog/Blog";


const ContentLoader = async ({params}) => {
    const slug = params["*"];
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/content/${slug}`); // Replace with your API endpoints
        return api.data
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

const BlogObjectLoader = async ({params}) => {
    const slug = params["route"];
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/blog/${slug}`); // Replace with your API endpoints
        return api
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

const ActivityLoader = async ({params}) => {
    const slug = params["slug"];
    try {
        // Example: Fetch routes from multiple APIs
        const api = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/spaces/categories/${slug}`); // Replace with your API endpoints
        const {data} = api

        if (data.success) {
            return data.data
        }

        throw new Response("Not Found", { status: 404 });
    } catch (err) {
        throw new Response("Not Found", { status: 404 });
    } finally {
    }
}

// Lazy load the components
const Root = lazy(() => import('./../../features/Layouts/Root'));
const PrivacyPolicy = lazy(() => import('./../../features/Components/Legal/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('./../../features/Components/Legal/CookiePolicy'));
const TermsAndConditions = lazy(() => import('./../../features/Components/Legal/TermsAndConditions'));
const ModernSlaveryPolicy = lazy(() => import('./../../features/Components/Legal/ModernSlaveryPolicy'));
const SpacesList = lazy(() => import('./../../features/Components/ListSpaces/SpacesList'));
const Space = lazy(() => import('./../../features/Components/ViewSpaces/Space'));

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Suspense fallback={<div>Loading...</div>}><Root /></Suspense>,
        errorElement:<ErrorBoundary />,
        children: [
            {
                loader: async () => redirect("/home"),
                index: true,
            },
            {
                path: "find-a-space",
                element: <Suspense fallback={<div>Loading...</div>}><SpacesList /></Suspense>,
            },
            {
                path: "privacy-policy",
                element: <Suspense fallback={<div>Loading...</div>}><PrivacyPolicy /></Suspense>,
            },
            {
                path: "cookie-policy",
                element: <Suspense fallback={<div>Loading...</div>}><CookiePolicy /></Suspense>,
            },
            {
                path: "terms-and-conditions",
                element: <Suspense fallback={<div>Loading...</div>}><TermsAndConditions /></Suspense>,
            },
            {
                path: "anti-slavery-policy",
                element: <Suspense fallback={<div>Loading...</div>}><ModernSlaveryPolicy /></Suspense>,
            },
            {
                path: "spaces",
                children: [
                    {
                        loader: async () => redirect("activities"),
                        index: true,
                    },
                    {
                        path: "activities",
                        element: <Activities />,
                    },
                    {
                        path: "activities/:slug",
                        element: <Category />,
                        loader: ActivityLoader,
                    },
                    {
                        path: ":slug",
                        element: <Suspense fallback={<div>Loading...</div>}><Space /></Suspense>
                    }

                ]
            },
            {
                path: "blog",
                children: [
                    {
                        path: "",
                        element: <PostType />
                    },
                    {
                        path: ":route",
                        element: <Blog />,
                        loader: BlogObjectLoader
                    },
                ]
            },
            {
                path: "*",
                element: <Page />,
                loader: ContentLoader
            }
        ],
    },
]);