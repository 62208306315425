import {Link, useNavigate} from "react-router-dom";

const ListItem = ({item}) => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {featured_asset, title, excerpt} = item

    const date = new Date(item?.created_at);

// Use Intl.DateTimeFormat to format the date as "day month year"
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(date);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/blog/${item?.slug}`);
    };

    return (
        <article id={'post_'+item.id} className={'cms_post_list_item'}>
            <Link to={`/blog/${item?.slug}`} title={item?.name}>
                <figure>
                    <img width={350} height={250} loading="lazy" src={baseUrl + featured_asset} alt={title}/>
                </figure>
            </Link>
            <div className={"cms_post_list_item__content group hover:cursor-pointer"} onClick={handleClick}>
                <p className={'cms_post_list_item__published'}>Published {formattedDate}</p>
                <h4 className={"cms_post_list_item__content_title group-hover:text-blaze-500"}>
                    {title}
                </h4>
                <p className={'cms_post_list_item__content_except'}>
                    {excerpt}
                </p>
            </div>
        </article>
    )

}
export default ListItem