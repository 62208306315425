import TimelineStepComponent from "./TimelineStep";
import {CohesiiContentComponent} from "../../../lib";

const NumberedTimelineComponent = ({content:data, order=1}) => {

    const {tag, title, content, position, steps,_uid} = data


    const titleData = {
        data: {
            'text': title,
            'tag': tag || 'h3',
        },
        type: 'title'
    }

    const contentData = {
        data: {
            'content': content,
            'classname': 'timeline-content'
        },
        type: 'code'
    }


    return (
        <section id={_uid} className={`order-${order} timeline-numbered center`}>
            <div className={'container'}>
                <CohesiiContentComponent content={titleData}/>
                <CohesiiContentComponent content={contentData}/>
                <div className={`steps ${position}`}>
                    {steps.map((block, index) => (
                        <TimelineStepComponent key={block.data._uid+index}  content={block.data}/>
                    ))}
                </div>
            </div>
        </section>

    )
}


export default NumberedTimelineComponent