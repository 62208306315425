import {CohesiiContentComponent} from "../../lib";


const Row = ({ content, order=1}) => {

    const {blocks, _uid, classname} = content

    return (
        <section id={`${_uid || ""}`} className={`container row order-${order} ${classname || ""}`.trim()}>
            {
                blocks.map((nested, index) => (
                    <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                ))
            }
        </section>
    )
}

export default Row