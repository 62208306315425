const Asset = ({ content }) => {
    const baseUrl = process.env.REACT_APP_STORAGE_URL;

    const {
        asset ="",
        asset_alt ="",
        asset_title ="",
        classname = "",
        _uid = ""
    } = content || {};

    const alt = asset_alt || asset_title ||  'Image description';
    const title  = asset_title || asset_alt || 'Image title';

    return (
        <>
        {
            (asset || null) &&
            (
                <figure id={`${_uid || ""} `} className={`image ${classname || ''}`.trim()}>
                    <img width={600} height={400} src={`${baseUrl}${asset}`} alt={alt} title={title}/>
                    {content?.caption && <figcaption>{content.caption}</figcaption>}
                </figure>
            )
        }
        </>
    );
};
export default Asset;