
import {lazy, Suspense, useEffect, useRef} from "react";
import {useLoaderData} from "react-router-dom";
import {useLoading} from "../../lib/LoadingContext";
import {Helmet} from "react-helmet-async";

const CohesiiContentComponent = lazy(() =>
    import('../../lib').then(module => ({ default: module.CohesiiContentComponent }))
);

const Page = () => {

    const pageRef = useRef(null)

    const {data} = useLoaderData()
    const {content, title, meta_title, meta_description} = data

    const { setLoading } = useLoading(); // Use loading context to show/hide the loading bar

    useEffect(() => {
        setLoading(true);  // Trigger loading state when the component mounts

        // Stop loading when data has been successfully loaded
        if (data) {
            setLoading(false);
        }

        return () => {
            setLoading(false);  // Ensure loading is stopped when the component unmounts
        };
    }, [data, setLoading]);

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://cohesii.com/home"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": title || meta_title,
                "item": window.location.href
            }
        ]
    }

    return (
        <>
            <Helmet>

                <title>Cohesii | {title || meta_title}</title>
                <meta name="description"
                      content={meta_description ?? ""}/>
                <meta property="og:title" content={"Cohesii - " + (title || meta_title)}/>
                <meta property="og:description" content={meta_description ?? ""}/>

                <meta property="og:image" content="https://www.example.com/image.jpg"/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={"Cohesii - " + (title || meta_title)}/>
                <meta name="twitter:description" content={meta_description ?? ""}/>
                <meta name="twitter:image" content="https://www.example.com/image.jpg"/>

                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>

            </Helmet>

            {
                content &&
                content.map((nested, index) => (

                    <Suspense fallback={<div>Loading...</div>}  key={nested.data._uid + index || index}>
                        <CohesiiContentComponent order={index} ref={pageRef}
                                                 content={nested}/>
                    </Suspense>

                ))
            }

        </>
    )
}

export default Page