import {Link} from 'react-router-dom'

const Button = ({content, action = null}) => {

    const {content: Text, link,  link_title, link_url, icon, icon_position, style, classname = '', _uid = '' } = content

    const hasIcon = !!icon
    const renderedClass = `group btn-${style} ${hasIcon ? ('has-icon icon-'+icon_position) :''} ${classname}`.trim()

    const linkReturn =
        (
            <Link id={_uid} to={link_url} title={link_title} className={renderedClass}>
                <span>{Text}</span>
            </Link>
        )

    const baseButton =
        (
            <div id={_uid} className={renderedClass} onClick={action}>
                <span>{Text}</span>
            </div>
        )

    return (
        <>
            {link ? linkReturn : baseButton}
        </>
    )

}

export default Button