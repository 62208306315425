import {CohesiiContentComponent} from "../../lib";
import {Helmet} from "react-helmet-async";

const Banner = ({content, order=1}) => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;

    const {strapline, title, classname = "", _uid, "additional-content": additional} = content || {}

    const imageData = {
        data: {...content, classname: 'self-center'},
        type: 'image',
    }

    const titleData = {
        data: {
            text: title
        },
        type: 'title'
    }

    return (
        <section id={`${_uid || ""} `} className={`banner row order-${order} ${classname || ""}`.trim()}>
            <Helmet>

                <link rel="preload" fetchPriority="high" as="image" href={`${baseUrl}${content.asset}`} type="image/webp"/>
                <link rel="preload"
                      href={`https://staging.cohesii.com/static/media/_bg_dot_effect.46a1602dbccec52e3596.svg`}
                      as="image"/>
            </Helmet>
            <div className={'container mx-auto row'}>
                <div className={'column py-[20px]'}>
                        <div className={'title-container'}>
                            {strapline && (<p className={'strapline'}>
                                {strapline }
                            </p>)}
                            <CohesiiContentComponent content={titleData}/>
                        </div>

                        <div className={'content'} dangerouslySetInnerHTML={{__html: content.content}} />

                        <div className={'additional'}>
                            {additional.map((nested, index) => (
                                <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                            ))}
                        </div>

                    </div>
                    <div className={'column'}>
                        <CohesiiContentComponent content={imageData}/>
                    </div>
                </div>
        </section>
)



}

export default Banner