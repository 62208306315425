import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CohesiiContentComponent} from "../../lib";

export const Faq = ({content}) => {

    const {icon, question, answer} = content
    const iconCode = icon ? `fa-solid ${icon.replace('fas-', 'fa-')}` : null;

    return (
        <div className={'faq'}>
            <div className={'faq__title'}>
                {icon &&  <FontAwesomeIcon icon={iconCode}  className={'text-blue-950 size-6'} />}
                {question}
            </div>
            <div className={'faq__content content'} dangerouslySetInnerHTML={{__html:answer}} />
        </div>
    )
}


export const Faqs = ({content}) => {

    const {_uid,
        classname,
        title ='',
        content:html = '',
        strapline = '',
        tag:Tag = 'h1',
        style = 'none',
        position = 'content-above',
        faq_style = 'list',
        blocks = []
    } = content

    const className = ` faqs ${position} style-${style} ${classname || ""}`.trim()

    return (
        <section id={`${_uid || ""}`} className={className}>
            <div className={'container'}>
                <div className={'faqs__content'}>
                    <div className={'title-container'}>
                        {strapline && (<p className={'strapline'}>{strapline}</p>)}
                        {title && (<Tag className={'title'}>{title}</Tag>)}
                    </div>

                    {html && <div className={'faqs__content_text content'} dangerouslySetInnerHTML={{__html:html}} /> }
                </div>
                <div className={`faqs__faq ${faq_style}-style`}>

                    {
                        blocks.map((nested, index) => (
                            <CohesiiContentComponent key={nested.data._uid || index} content={nested} />
                        ))
                    }

                </div>
            </div>
        </section>
    )
}