import React, {useState, useContext, createContext, useCallback, lazy, Suspense} from 'react';

const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};

const Modal = lazy(() => import('./Modal'));


export const ModalProvider = ({ children }) => {
    const [isShowing, setIsShowing] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const showModal = useCallback((content) => {
        setModalContent(() => content);
        setIsShowing(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsShowing(false);
        setModalContent(null);
    }, []);

    return (
        <ModalContext.Provider value={{ showModal, hideModal, isShowing }}>
            {children}
            <Suspense fallback={<div>Loading...</div>}>
                <Modal isShowing={isShowing} hide={hideModal}>
                    {modalContent}
                </Modal>
            </Suspense>
        </ModalContext.Provider>
    );
};