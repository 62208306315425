import {CohesiiContentComponent} from "../../../lib";
import Breadcrumbs from "../Breadcrumbs";
import LatestListWidget from "./LatestListWidget";

const Post = ({data}) => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {title, block_content, excerpt, featured_asset, slug} = data
    const date = new Date(data?.created_at);

// Use Intl.DateTimeFormat to format the date as "day month year"
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(date);

    return (
        <>
            <section className={'cms_post__header'}>
                <div className={'container'}>
                    <p className={'cms_post__published'}>Published {formattedDate}</p>
                    <h1 className={'cms_post__header_title'}>{title}</h1>
                    <p className={'cms_post__excerpt'}>{excerpt}</p>
                </div>
            </section>
            <section className={'cms_post__content'}>
                <div className={'container !pt-0 py-8'}>
                    <Breadcrumbs crumbs={[
                        {path: '/', label: 'Home'},
                        {path: '/blog', label: 'Blog'},
                        {path: `/blog/${slug}`, label: title},
                    ]}/>
                </div>
                <div className={'container'}>

                    <figure>
                        <img src={baseUrl + featured_asset} alt={title}/>
                    </figure>

                    {
                        block_content.map((nested, index) => (
                            <CohesiiContentComponent key={nested.data._uid || index} content={nested}/>
                        ))
                    }
                </div>
            </section>

            <section id={"latest_posts"} className={'latest-blogs  order-10'}>

                <div className={'container row'}>
                    <div className={'column justify-center'}>
                        <div className={'title-container mb-6'}>
                            <p className={'strapline'}>Latest Blogs</p>
                            <h4 className={'title'}>Keep up to date with the latest news from Cohesii</h4>
                        </div>

                        <LatestListWidget limit={4}/>
                    </div>
                </div>


            </section>
        </>
    )
}

export default Post