import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import axios from 'axios';
import useIpAndLocation from "../../../hooks/useIpAndLocation";
import store from "../../../app/store";
import {redirect, useNavigate} from "react-router-dom";

// Recursive function to process nested categories into react-select format
const processCategories = (categories) => {
    return categories.map(category => {
        // Create the main category object without options
        const categoryObject = {
            label: category.name, // React-Select uses `label` for display
            value: category.id,   // `value` is the actual ID of the category
            slug: category.slug,  // Additional info if needed (can be logged on select)
            image_path: category.image_path,
            description: category.description,
            options: [], // Initialize as empty, we will add options later
        };

        // If there are subcategories, process them and add to options
        if (category.subcategories) {
            categoryObject.options = processCategories(category.subcategories);

            // Create the "All" option for the current category
            const allOption = {
                label: `All ${category.name}`, // Set the label to "All {Name}"
                value: category.id,                  // Use null or a specific value for "All"
                slug: '',                     // No slug for "All"
                image_path: '',               // No image for "All"
                description: '',              // No description for "All"
            };

            // Add the "All" option to the options array of the category object
            categoryObject.options.unshift(allOption); // Add "All" option at the beginning
        }

        return categoryObject; // Return the modified category object
    });
};

const findDefaultValue = (options, defaultValue) => {
    for (const option of options) {

        // Check if the current option matches the default value
        if (option.value === defaultValue) {
            return option; // Return if found
        }
        // If it has sub-options, recursively check those
        if (option.options) {
            const found = findDefaultValue(option.options, defaultValue);
            if (found) return found; // Return the found value
        }
    }
    return null; // Return null if not found
};

const SearchComponent = ({defaultActivityValue}) => {
    const [activityOptions, setActivityOptions] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const {storedLocation} = useIpAndLocation()
    const [location, setLocation] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        if (storedLocation) {
            setLocation(storedLocation);
        }
    }, [storedLocation]);


    // Fetch categories from API
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // Replace with your actual API endpoint
                const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/spaces/categories/tree`);
                const categoriesData = response.data;

               if (categoriesData.sucess) {

                   // Process nested categories into a react-select compatible format
                   const processedOptions = processCategories(categoriesData?.data);
                   setActivityOptions(processedOptions);
               }
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const defaultOption = findDefaultValue(activityOptions, defaultActivityValue);
        setSelectedActivity(defaultOption); // Set the default selected activity
    }, [activityOptions, defaultActivityValue]); // Run effect when activityOptions change

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate inputs
        const formErrors = {};
        if (!selectedActivity) formErrors.activity = 'Activity is required';
        if (!location) formErrors.location = 'Location is required';

        // Check if there are errors
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Clear errors if form is valid
        setErrors({});

        const url =  `/find-a-space?location=${encodeURIComponent(location)}&activity=${encodeURIComponent(selectedActivity.label)}`

        // Reset form (optional)
        setSelectedActivity(null);
        setLocation('');

        navigate(url)
    };


    return (
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4 mt-4 pr-[60px]">
            {/* Activity Dropdown Field */}
            <div className="w-full">
                <div className="focus-within:border-blaze-500 flex items-center border border-gray-300 rounded-lg px-3 py-2 h-12">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400 mr-3" />
                    <Select
                        options={activityOptions} // Grouped options
                        value={selectedActivity} // Controlled value
                        onChange={setSelectedActivity} // Handle change
                        isLoading={loading}
                        placeholder="Select your activity"
                        className="w-full h-12 group-focus:border-blaze-500" // Full width and fixed height
                        classNamePrefix="react-select" // Prefix for styling
                        isSearchable

                        styles={{
                            control: (provided) => ({
                                ...provided,
                                height: '100%', // Ensure full height
                                border: 'none', // Remove border
                                boxShadow: 'none', // Remove shadow
                                backgroundColor: 'transparent', // Transparent background
                                display: 'flex', // Use flex for proper alignment
                                alignItems: 'center', // Center items vertically
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9999, // Ensure dropdown appears above other elements
                            }),
                        }}
                    />
                </div>
                {errors.activity && (
                    <p className="text-red-500 text-sm mt-1">{errors.activity}</p>
                )}
            </div>

            {/* Location Search Field */}
            <div className="w-full">
                <div className="focus-within:border-blaze-500 flex items-center border border-gray-300 rounded-lg px-3 py-2 h-12">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-400 mr-3"/>
                    <input
                        type="text"
                        placeholder="Enter a city or address"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className=" w-full rounded-lg px-3 focus:outline-none focus:ring-0 focus:border-none border-none" // Remove focus outlines and borders
                    />
                </div>
                {errors.location && (
                    <p className="text-red-500 text-sm mt-1">{errors.location}</p>
                )}
            </div>

            {/* Search Button */}
            <button
                type="submit"
                className="btn-primary w-full !text-2xl !rounded-lg"
            >
                Search
            </button>
        </form>
    );
};

export default SearchComponent;