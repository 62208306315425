import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CohesiiContentComponent} from "../../lib";

export const IconGroup = ({content, order=1}) => {

    const {blocks, title, strapline, tag:Tag, style="None", content:text} = content
    return (
        <section className={`icon-group order-${order} ${style}`}>
            <div className={'container flex flex-col gap-4'}>
                {(title || strapline) && (
                    <div className={'title-container'}>
                        {strapline && (<p className={'strapline'}>{strapline}</p>)}
                        {title && (<Tag className={'title'}>{title}</Tag>)}
                    </div>
                )}
                {text &&
                    <div className={'icon-group__content mt-4 mb-4'} dangerouslySetInnerHTML={{__html: text}}/>
                }

                <div className={'icon-group__icons'}>
                    {
                    blocks.map((nested, index) => (
                        <CohesiiContentComponent key={nested.data._uid + index || index} content={nested} />
                    ))
                }
                </div>
            </div>
        </section>
    )
}

const Icon = ({content}) => {

    const {icon, background, title, tag:Tag, content: html, "icon-position": position, bordered = 'no', _uid=""} = content;

    const hasBackground = background === 'no' ? '': `bg-${background}`
    const iconCode = `fa-solid ${icon.replace('fas-', 'fa-')}`;
    const border = bordered === 'yes' ? 'border' :'';

    if (position === 'icon-only') {
        return  (
            <div className={`icon ${border} ${hasBackground}`.trim()}>
                <FontAwesomeIcon icon={iconCode}  className={'text-blue-950 size-8'} />
            </div>
        )
    }

    return (
        <div id={_uid} className={`icon ${border} ${hasBackground}`.trim()}>
            <div className={`icon-container ${position}`}>
                <FontAwesomeIcon icon={iconCode} className={'text-blue-950 icon'} />
                <Tag className={'icon-title'}>{title}</Tag>
            </div>
            {
                html && <div className={'content'} dangerouslySetInnerHTML={{__html: html}}/>
            }
        </div>
    )

}

export  default  Icon