import './Blog.css'
import {useEffect, useRef} from "react";
import {useLoaderData} from "react-router-dom";
import {useLoading} from "../../../lib/LoadingContext";
import {Helmet} from "react-helmet-async";
import Post from "./Post";

import Category from "./Category";

const CatHelmet = ({data}) => {

    const title = "Cohesii | Blog - "+ (data?.seo_title || data?.name);
    const desc = data?.seo_description || data?.excerpt
    const url = `https://cohesii.com/blog/${data?.slug}`

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title}/>
            <meta name="description"
                  content={desc}/>
            <meta name="keywords"
                  content={data?.seo_keywords}/>
            <link rel="canonical" href={`https://cohesii.com/blog/${data?.slug}`}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description"
                  content={desc}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={url}/>
            <meta property="og:image" content="https://yourwebsite.com/path-to-category-image.jpg"/>
            <meta property="og:site_name" content="Cohesii"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description"
                  content={desc}/>
            <meta name="twitter:image" content="https://yourwebsite.com/path-to-category-image.jpg"/>
            <meta name="twitter:site" content="@yourtwitterhandle"/>
            <meta name="twitter:creator" content="@yourtwitterhandle"/>
        </Helmet>
    )
}

const PostHelmet = ({data}) => {

    const title = "Cohesii | Blog - "+ (data?.seo_title || data?.name);
    const desc = data?.seo_description || data?.excerpt
    const url = `https://cohesii.com/blog/${data?.slug}`
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title}/>
            <meta name="description"
                  content={desc} />
            <meta name="keywords"
                  content={data?.seo_keywords}/>
            <link rel="canonical" href={url}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description"
                  content={desc} />
            <meta property="og:type" content="article"/>
            <meta property="og:url" content={url}/>
            <meta property="og:image" content="https://yourwebsite.com/path-to-category-image.jpg"/>
            <meta property="og:site_name" content="Cohesii"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description"
                  content={desc} />
            <meta name="twitter:image" content="https://yourwebsite.com/path-to-category-image.jpg"/>
            <meta name="twitter:site" content="@yourtwitterhandle"/>
            <meta name="twitter:creator" content="@yourtwitterhandle"/>
        </Helmet>
    )
}

const Blog = () => {

    const {data} = useLoaderData()
    const {type, data:content} = data

    const {setLoading} = useLoading(); // Use loading context to show/hide the loading bar

    useEffect(() => {
        setLoading(true);  // Trigger loading state when the component mounts

        // Stop loading when data has been successfully loaded
        if (data) {
            setLoading(false);
        }

        return () => {
            setLoading(false);  // Ensure loading is stopped when the component unmounts
        };
    }, [data, setLoading]);

    return (
        <div className={'cms__blog'}>
            {type === 'post' &&
                <>
                    <PostHelmet data={content} />
                    <Post data={content} />
                </>
            }
            {type === 'category' &&
                <>
                    <CatHelmet data={content} />
                    <Category category={content} />
                </>
            }
        </div>
    )
}

export default Blog