import {useLoading} from "../../../lib/LoadingContext";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import BlogPostList from "./List";
import {Link, useSearchParams} from "react-router-dom";
import Pagination from "./Pagination";
import Breadcrumbs from "../Breadcrumbs";
import Loading from "../Loading";

const PostType = () => {

    const {setLoading} = useLoading(); // Use loading context to show/hide the loading bar

    const [searchParams, setSearchParams] = useSearchParams();
    const [cats, setCats] = useState([]);
    const [pagination, setPagination] = useState({});

    const handlePageChange = (newPage) => {
        // Update the query parameter
        setSearchParams({ p: newPage });
    };

    useEffect(() => {
        return () => {
            setLoading(false);  // Ensure loading is stopped when the component unmounts
        };
    }, [setLoading]);


    return (
        <div className={'cms__blog'}>
            <Helmet>
                <title>Cohesii | Blog - Explore Insights and Tips on Commercial Space Management and Utilization</title>
                <meta name="description"
                      content="Discover expert advice, industry insights, and success stories on commercial space management, optimization, and utilization. Stay ahead of market trends with Cohesii."/>
                <meta name="keywords"
                      content="commercial space management, property optimization, industry insights, success stories, Cohesii blog, commercial real estate, workspace solutions"/>
                <link rel="canonical" href="https://cohesii.com/blog"/>
            </Helmet>
            <section className={'cms_post__header'}>
                <div className={'container'}>
                    <p className={'cms_post__published'}>Cohesii Blog</p>
                    <h1 className={'cms_post__header_title'}>Explore Insights and Tips on Commercial Space Management
                        and Utilization</h1>
                    <p className={'cms_post__excerpt'}>
                        Welcome to the Cohesii Blog, your go-to resource for expert advice, industry insights, and
                        success stories on commercial space management. Whether you're a property owner, freelancer,
                        startup, or event planner, our articles cover everything you need to know to optimize, manage,
                        and profit from your commercial spaces. Dive into our wealth of knowledge and stay ahead of
                        market trends with Cohesii.
                    </p>
                </div>
            </section>
            <section className={'cms_post_list'}>

                <div className={'container !pt-0 py-8'}>
                    <Breadcrumbs crumbs={[
                        { path: '/', label: 'Home' },
                        { path: '/blog', label: 'Blog' },
                    ]} />
                </div>

                <div className={'container'}>
                    <div className={'column max-w-full md:max-w-[25%] order-2 md:order-1'}>
                        <h3 className={'cms_post_list_sidebar_title'}>Categories</h3>
                        <Loading isLoading={cats.length === 0} />
                        <ul className={'cms_post_list_sidebar_list'}>
                            {cats?.length > 0 && cats.map((cat, index) => (
                                <li>
                                    <Link to={`/blog/${cat.slug}`} title={cat.name}>
                                        {cat.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={'column order-1'}>
                        <BlogPostList setCats={setCats} setPagination={setPagination}/>
                    </div>

                </div>
                <div className={'container flex flex-1 w-full'}>
                    <Pagination pagination={pagination} handlePageChange={handlePageChange}/>
                </div>
            </section>
        </div>
    )
}

export default PostType